import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/Seo"

const NotFoundPage = ({ pageContext }) => {
  const { langKey } = pageContext
  return (
    <Layout langKey={langKey}>
      <SEO title="404: Not found" />
      <h1>NOT FOUND</h1>
    </Layout>
  )
}

export default NotFoundPage
